import $vuetify from 'vuetify/es5/locale/de'

export default {
  generic: {
    ok: 'Ok',
    no: 'Nein',
    yes: 'Ja',
    save: 'Speichern',
    saved: 'Gespeichert',
    saving: 'Speichert',
    saveNow: 'Jetzt speichern',
    loading: 'Wird geladen',
    success: 'Erfolg',
    error: 'Fehler',
    errorMsg: 'Etwas ist schief gelaufen, bitte versuche es erneut.',
    cancel: 'Abbrechen',
    search: 'Suche',
    searchNoResults: "Keine Suchergebnisse gefunden für '{search}'",
    update: 'Aktualisieren',
    remove: 'Löschen',
    submit: 'Abschicken',
    gotIt: 'Verstanden!',
    apply: 'Anwenden',
    menu: 'Menü',
    back: 'Zurück',
    more: 'Mehr',
    none: 'Keiner',
    close: 'Schließen',
    route: 'Route',
    routes: 'Routen',
    thisRoute: 'diese Route',
    climbing: 'Klettern',
    rope: 'Seil',
    boulder: 'Boulder',
    boulders: 'Boulder',
    thisBoulder: 'diesen Boulder',
    bouldering: 'Bouldern',
    settings: 'Einstellungen',
    readMore: 'Mehr lesen',
    details: 'Details',
    today: 'Heute',
    tomorrow: 'Morgen',
    total: 'Gesamt',
    copied: 'Kopiert',
    comingSoon: 'Demnächst verfügbar',
    linkCopiedMsg: 'Der Link wurde in die Zwischenablage kopiert',
    noData: 'Keine Daten verfügbar',
    refresh: 'Aktualisieren',
    offlineMsg: 'Sie sind offline.',
    offlineMsgSave: 'Änderungen werden gespeichert, wenn Sie wieder online gehen.',
    offlineMsgNotSaved: 'Ihre Änderungen werden nicht gespeichert.',
    badConnection: 'Schlechte Verbindung',
    pagination: {
      frst: 'Erste Seite',
      prev: 'Vorherige Seite',
      next: 'Nächste Seite',
      last: 'Letzte Seite',
    },
  },
  inputRules: {
    required: 'Erforderlich',
    minLength: 'Sollte mindestens {length} Zeichen sein',
    maxLength: 'Sollte höchstens {length} Zeichen umfassen',
    minVal: 'Sollte mindestens {minVal} sein',
    maxVal: 'Sollte höchstens {maxVal} sein',
    emailInvalid: 'Ungültige E-Mail',
    shouldMatchPwd: 'Sollte mit Passwort übereinstimmen',
    dateInvalid: 'Ungültiges Datum',
    timeInvalid: 'Ungültige Zeit',
  },
  sidenav: {
    profilePic: 'Profilbild',
    withoutName: 'Benutzer ohne Namen',
    atGym: 'Bei {gymName}',
    profile: 'Profil',
    myProfile: 'Mein Profil',
    switchGym: 'Halle wechseln',
    selectGym: 'Halle auswählen',
    signOut: 'Ausloggen',
    signIn: 'Anmelden',
    closeApp: 'App schließen',
    routes: 'Routen',
    boulders: 'Boulder',
    dashboard: 'Dashboard',
    competitions: 'Wettkämpfe',
    myGym: 'Meine Kletterhalle',
    inbox: 'Inbox',
    reservations: 'Reservierungen',
    editRoutes: 'Routen bearbeiten',
    editBoulders: 'Boulder bearbeiten',
    editCompetitions: 'Wettkämpfe bearbeiten',
    communications: 'Mitteilungen',
    statistics: 'Statistiken',
    gym: 'Halle',
    monitoring: 'Überwachung',
    help: 'Hilfe',
    aboutUs: 'Über uns',
    contact: 'Kontakt',
    sendFeedback: 'Feedback senden',
    toc: 'Nutzungsbedingungen ',
  },
  auth: {
    loginTitle: 'Anmelden | TopLogger',
    loginDescription: 'Logge dich in die Toplogger Web App ein oder erstelle dein Konto.',
    resetPwdTitle: 'Passwort zurücksetzen | TopLogger',
    resetPwdDescription: 'Setze dein Passwort für TopLogger zurück',
    unauthorized: 'Nicht autorisiert',
    login: 'Login',
    signIn: 'Anmelden',
    guestAccount: 'Gästekonto',
    resetPwd: 'Passwort zurücksetzen',
    password: 'Passwort',
    passwordConfirm: 'Passwort bestätigen',
    newsletters: 'Newsletter abonnieren',
    forgotBtn: 'Passwort vergessen?',
    forgotHeader: 'Hast du dein Passwort vergessen?',
    forgotExpl:
      'Mach dir keine Sorgen. Gib einfach deine E-Mail-Adresse ein und wir senden dir eine E-Mail mit Anweisungen zur Wiederherstellung.',
    remember: 'Wieder daran erinnern?',
    signUp: 'Registrieren',
    google: 'Mit Google anmelden',
    facebook: 'Mit Facebook anmelden',
    createAccount: 'Erstelle ein Konto',
    createsAccount: 'Erstellt automatisch ein Konto, das mit Ihrer E-Mail verknüpft ist',
    haveAccount: 'Hast du schon ein Konto?',
    msgLoggedIn: 'Angemeldet',
    msgLoggedOut: 'Abgemeldet',
    msgSentConfirm: 'Sie erhalten eine E-Mail zur Bestätigung.',
    msgSentReset: 'Sie erhalten eine E-Mail, um Ihr Passwort zurückzusetzen.',
    msgPwdReset: 'Erfolg: Ihr Passwort wurde aktualisiert.',
    msgSentConfirmNew: 'Eine E-Mail wird zur Bestätigung an Ihre neue Adresse gesendet.',
    msgPwdUpdated: 'Ihr Passwort wurde aktualisiert.',
    loginToSaveProgress: 'Sie müssen angemeldet sein, um Ihren Fortschritt zu speichern',
    actionRequiresLogin: 'Für diese Aktion müssen Sie angemeldet sein.',
  },
  profile: {
    title: 'Dein Profil | TopLogger',
    description: 'Ändere dein Profil und passe deine Einstellungen und Standardeinstellungen für TopLogger an',
    info: {
      changePicture: 'Profilbild ändern',
      zoomToCrop: 'Zum Zuschneiden zoomen und ziehen',
      upload: 'Hochladen',
      socialMedia: 'Sozialen Medien',
      changeFile: 'Datei ändern',
      chooseFile: 'Datei wählen',
      firstName: 'Vorname',
      lastName: 'Nachname',
      male: 'Männlich',
      female: 'Weiblich',
      other: 'Anderes',
      country: 'Land',
      city: 'Stadt',
      dateBirth: 'Geburtsdatum',
      hideDateBirth: 'Verstecke das Datum vor anderen Benutzern',
      newsletters: 'Newsletter erhalten',
      anonymous: 'Anonym',
      length: 'Größe (cm)',
      weight: 'Gewicht (kg)',
      email: 'E-Mail',
      changeMail: 'E-Mail ändern',
      enterNewMail: 'Bitte gib deine neue E-Mail-Adresse ein',
      newEmail: 'Neue E-Mail',
    },
    password: {
      changePwd: 'Passwort ändern',
      newPwd: 'Neues Passwort',
      confirmNewPwd: 'Neues Passwort bestätigen',
    },
    preferences: {
      preferences: 'Präferenzen',
      language: 'Sprache',
      anonymousSuffix: 'In Kletterhallen-Ranglisten verstecken',
      genderSetToast: "Um in der Rangliste zu erscheinen, wurde dein Geschlecht auf 'Männlich' gesetzt",
      anonymousSetToast: 'Sie sind jetzt anonymisiert: Rankings erfordern ein Geschlecht.',
    },
    notifications: {
      notifications: 'Benachrichtigungen',
      channels: 'Kanäle',
      push: 'Push',
      newRoutes: 'Neue Routen',
      newBoulders: 'Neue Boulder',
      news: 'Nachrichten',
      competitions: 'Wettkämpfe',
      restrictRange: 'Schwierigkeitsgradsbereich einschränken',
      onlyRoutesBetween: 'Nur wenn neue Routen dazwischen',
      onlybouldersBetween: 'Nur wenn neue Boulder dazwischen',
      selectGym: 'Wählen Sie eine Kletterhalle',
      gymsSelected: '{number} ausgewählt',
      noGuarantee:
        'Hinweis: Der Administrator entscheidet, welche Updates gesendet werden. Es gibt also keine Garantie, dass Sie immer benachrichtigt werden.',
    },
  },
  gyms: {
    title: 'Verfügbare Kletterhallen | TopLogger',
    description:
      'Eine Auflistung aller in TopLogger verfügbaren Kletterhallen. Wähle deine Kletterhalle aus, um seine Routen und Boulder einzutragen.',
    gyms: 'Kletterhallen',
    visitedGyms: 'Besuchte Kletterhallen',
    visitedGymsSubhead: 'Wo du etwas eingetragt hast letzten sechs Monate',
    gymsNearby: 'Halle in der Nähe',
    selectGym: 'Wähle deine aktuelle Kletterhalle aus',
    goToGym: 'Geh zur Halle',
    gymLogo: 'Kletterhallen Logo',
    commingSoon: 'Demnächst verfügbar...',
    noSwitching: 'Um Kletterhallen zu wechseln, lade die kostenlose App selbst herunter.',
    notAllowed: 'Es tut uns leid! Nicht erlaubt...',
  },
  climbs: {
    title: '{routesBoulders} derzeit bei {gymName} | TopLogger',
    descriptionMap: 'Eine interaktive Karte mit allen {routesBoulders}, die derzeit bei {gymName} verfügbar sind.',
    descriptionList: 'Eine Auflistung aller {routesBoulders}, die derzeit unter {gymName} verfügbar sind.',
    listView: 'Listenansicht',
    mapView: 'Kartenansicht',
    showList: 'Liste anzeigen',
    viewMap: 'Karte zeigen',
    new: 'Neu',
    guestMsg: 'Dies ist ein Gastkonto.',
    groups: 'Gruppen',
    walls: 'Wände',
    filters: {
      filters: 'Filter',
      filterBtn: 'Kletterrouten filtern',
      activeMsg: 'Filter sind aktiv',
      grade: 'Schwierigkeitsgrad',
      starRating: 'Bewertung in Sternen',
      colors: 'Farben',
      todo: 'Todo',
      almostRemoved: 'Fast entfernt',
      forChildren: 'Für Kinder geeignet.',
      selectAll: 'Alle auswählen',
      selectAllFiltered: 'Alle gefilterten auswählen',
      selectAllInView: 'Alles in der Ansicht auswählen',
      deselectAll: 'Alle aufheben',
      clearFilters: 'Filter zurücksetzen',
    },
    group: {
      sharedWith: 'Gemeinsame Gruppe mit:',
      hiddenWall: '1 Wand durch Filter ausgeblendet',
      hiddenWalls: '{count} durch Filter ausgeblendete Wände',
      hiddenGroup: '1 Gruppe durch Filter ausgeblendet',
      hiddenGroups: '{count} Gruppen, die durch Filter ausgeblendet werden',
      hiddenRoute: '1 Route durch Filter ausgeblendet',
      hiddenRoutes: '{count} durch Filter ausgeblendete Routen',
      hiddenBoulder: '1 Boulder durch Filter ausgeblendet',
      hiddenBoulders: '{count} durch Filter ausgblendete Boulder',
    },
    log: {
      done: 'Fertig',
      top: 'Top',
      flash: 'Flash',
      onsight: 'On-Sight',
      rp: 'RP',
      fl: 'FL',
      os: 'OS',
      repeat: 'Wiederholung',
      check: 'Prüfen',
      tickOff: 'Abhaken',
      zones: 'Zonen',
      zone: 'Zone',
      myOpinion: 'Meine Meinung',
      tries: 'Versuche',
      repeats: 'Wiederholungen',
      uncheckMsg: 'Dies entfernt eine Begehung von {timeAgo}.',
      removeAscend: 'Begehung entfernen',
      project: 'Projekt',
      getApp: {
        toastTitle: 'Nicht hier',
        toastMsg: 'Gehe auf toplogger.nu, um es auf deine eigenen Geräte zu bekommen ...',
        header: 'Diese App ist gesperrt',
        title: 'Hol es dir auf dein eigenes Gerät',
        explanation:
          'Um deinen Fortschritt speichern zu können, gehe zu <span class="tl-text-primary">toplogger.nu</span>und lade die <i>kostenlose</i> App herunter oder verwende die Web-Version.',
      },
      all: {
        areYouSure: 'Bist du sicher?',
        update: 'Dadurch werden Begehungen von {timeAgo} aktualisiert.',
        uncheck: 'Dies entfernt eine Begehung pro {routeBoulder}, einschließlich der Begehungen von {timeAgo}.',
        updateAscends: 'Begehungen aktualisieren',
        removeAscends: 'Begehungen entfernen',
        logRoutes: 'Alle {count} Routen  eintragen',
        logBoulders: 'Alle {count} Boulder eintragen',
      },
      stats: {
        stats: 'Statistiken',
        statistics: 'Statistiken',
        dateSet: 'Schrauberdatum',
        dateRemoved: 'Entfernungsdatum',
        votesTitle: 'Schwierigkeitsbewertungen',
        votesEmpty: 'Noch keine Bewertung vorhanden...',
        votesTotal: '{votes} insgesamt',
        ratingTitle: 'Bewertungsübersicht',
        ratingEmpty: 'Noch keine Bewertungen...',
        toppedHeader: 'Geklettert von',
        toppedSubhead: 'Anonyme Benutzer ausschließen',
        notToppedBoulder: 'Dieser Boulder wurde noch nicht geklettert.',
        notToppedRoute: 'Dieser Route wurde noch nicht geklettert.',
      },
      renew: {
        renewedSoonBoulder: 'Bald erneuert',
        renewedSoonRoute: 'Bald erneuert',
        vote: 'Für Erneuerung abstimmen',
        unvote: 'Stimme für Erneuerung entfernen',
        voted: 'Abgestimmt (anonym)',
        votedMsg: 'Es wurde eine Abstimmung hinzugefügt, um {thisRouteBoulder} zu erneuern.',
        unvoted: 'Stimme entfernt',
        unvotedMsg: 'Ihre Stimme um {thisRouteBoulder} zu erneuern wurde entfernt.',
      },
      report: {
        report: 'Melden',
        reportClimb: 'Melden Sie {thisRouteBoulder}',
        explanation: 'Etwas stimmt nicht, warne die Kletterhalle.',
        messageLabel: 'Nachricht an die Kletterhalle',
        successMsg: 'Eine Benachrichtigung wird an die Kletterhalle gesendet.',
      },
      help: {
        header: 'Erklärungen zum Eintragen ',
        buttons: {
          header: 'Eintragebuttons',
          subhead: 'Welcher Button gilt wann?',
          topExpl: 'Du hast es geklettert.',
          flashExpl: 'Du hast es in deinem ersten Versuch geklettert.',
          os: 'OS: On-Sight',
          osExpl: 'Im ersten Versuch geklettert<sup>1</sup>, ohne Beta<sup>2</sup>.',
          fl: 'FL: Flash',
          flExpl: 'Im ersten Versuch geklettert<sup>1</sup>, aber mit Beta<sup>2</sup>.',
          rp: 'RP: Rotpunkt',
          rpExpl: 'Geklettert <sup>1</sup>, aber nicht im ersten Versuch.',
          elaboration:
            'Rotpunkten besteht oft aus der Ausarbeitung aller Bewegungen und an der Route für einige Zeit zu arbeiten.',
          sup1: '<sup>1</sup> Geklettert: Bis oben klettern ohne zu stürzen und ohne jegliche Unterstüzung vom Seil.',
          sup2:
            '<sup>2</sup> Beta: Jegliches Wissen über die Route von anderen, entweder indem man etwas gesagt bekommt oder jemanden klettern sieht.',
        },
        stability: {
          header: 'Stabilitätsanzeige',
          explanation:
            'Der Punkt unter den Schwierigkeitsgrad weist auf die Gradstabilität hin. Es kann drei Werte annehmen:',
          unstableText: '<strong>Instabil</strong>: Noch nicht genug Stimmen. Diese Note unterliegt noch Änderungen.',
          midStableText:
            '<strong>Wahrscheinlich stabil</strong>: Einige zusätzliche Stimmen werden benötigt, aber der Schwierigkeitsgrad stabilisiert sich.',
          stableText:
            '<strong>Stabil</strong>: Viele Benutzer haben über den Schwierigkeitsgrad abgestimmt. Er wird wahrscheinlich so bleiben wie er ist.',
        },
        questions: {
          QgradeChange: 'Was passiert mit meiner Punktzahl, wenn sich der Schwierigkeitsgrad ändert?',
          AgradeChange: 'Keine Sorge: Es wird sofort im laufenden Betrieb neu berechnet.',
        },
      },
    },
  },
  dashboard: {
    title: 'TopLogger-Dashboard für {gymName}',
    description:
      'Kletterhallen Ranglisten, neue Kletterrouten, deine persönlichen Statistiken und Fortschritte bei {gymName}.',
    otherUserMsg: 'Statistiken von {userName} werden angezeigt',
    viewClimbs: '{routesBoulders} anzehen',
    showMine: 'Zeig meine',
    showDemoGraph: 'Demo-Grafik anzeigen',
    showingDemoGraph: 'Dies ist eine Demo-Grafik',
    notEnoughLogged: 'Sie haben sich nicht lange genug Begehungen eingetragt, um ein korrektes Diagramm zu erstellen.',
    guestUser: 'Gastbenutzer',
    statsLoadErrorMsg: 'Benutzerstatistiken konnten nicht abgerufen werden.',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    link: 'Link',
    score: {
      subhead: 'Statistiken und Fortschritt',
      countHeader: '{routesBoulders}anzahl',
      countHeaderExpl: 'Anzahl der gekletterten {routesBoulders} bei {gymName}',
      gradeHeader: '{routeBoulder} Schwierigkeitsgrad',
      gradeHeaderExpl: 'Durchschnitt der Top 10 Begehungen der letzten zwei Monate, geklettert bei {gymName}',
      historyHeader: '{routeBoulder} Bewertungsverlauf',
      historySubhead: 'Schwierigkeitsgrad basierend auf Begehungen in <strong>all</strong> Kletterhallen.',
      countBarText: '{count} von {total}',
      gradeBarTooltip: '{percentLeft}% übrig, um zu erreichen ',
      effective: 'Wirksam',
      bonus: 'Bonus',
      points: 'Punkte',
      average: 'Durchschnitt',
      bonusExplHeader: 'Über Bonuspunkte',
      bonusExplOS: '<b>On-sight</b> fügt zwei französische Schwierigkeitsgrade hinzu, was 33,33 Punkten entspricht.',
      bonusExplFL: '<b>Flash</b> fügt einen französischen Schwierigkeitsgrad hinzu, was 16,66 Punkten entspricht.',
    },
    ranking: {
      header: 'Ranglisten fur {gymName}',
      noName: 'Kein Name',
      setNameMsg:
        'Bitte gib deinen Namen ein, um in der Rangliste erscheinen. Du kannst dort immer noch auf anonym wechseln.',
      anonymousMsg: 'Du bist anonym, also wirst du nicht in den Ranglisten erscheinen.',
      countHeader: 'Begehungs­rangliste',
      countSubhead: 'Die meisten Begehungen bei {gymName}',
      gradeHeader: 'Schwierigkeits­rangliste',
      gradeSubhead: 'Im Durchschnitt der Top 10 Begehungen der letzten zwei Monate',
      all: 'Alle',
      tooFewRoutesMsg0: 'Du hast noch keine Routen geklettert.',
      tooFewRoutesMsg1: 'Du hast erst eine Route geklettert.',
      tooFewRoutesMsg: 'Du hast erst {count} Routen geklettert.',
      tooFewBouldersMsg0: 'Du hast noch keine Boulder geklettert.',
      tooFewBouldersMsg1: 'Du hast erst einen Boulder geklettert.',
      tooFewBouldersMsg: 'Du hast erst {count} Boulder geklettert.',
      logMoreMsg: 'Trage {count} mehr ein für einen richtigen Top-10-Durchschnitt.',
      logRoutesBtn: 'Routen eintragen',
      logBouldersBtn: 'Boulder eintragen',
      tooFewLogsMsg: 'Basiert auf {count} Begehungen',
    },
    oldNew: {
      header: '{routeBoulder}-Updates',
      subhead: 'Einige Updates zu {routesBoulders} in deiner Kletterhalle',
      lastIn: 'Zuletzt geschraubt',
      newClimbs: 'Neue {routesBoulders}',
      firstOut: 'Zuerst entfernt',
      oldClimbs: 'Fast entfernte {routesBoulders}',
      daysLeft: '{count} verbleibende Tage',
      nothing: 'Nichts...',
    },
  },
  competitions: {
    title: 'Wettkämpfe bei {gymName} | TopLogger',
    description:
      'Eine Auflistung aller Wettkämpfe bei {gymName}. Dazu gehören vergangene Wettkämpfe mit ihren Platzierungen sowie aktuelle Wettkämpfe.',
    compTitle: '{competitionName} bei {gymName} | TopLogger',
    compDescription: "Die Ergebnisse des Wettkampfs '{competitionName}' bei {gymName}.",
    compClimbsTitle: "Die {routesBoulders} für '{competitionName}' | TopLogger",
    compClimbsDescMap: 'Eine interaktive Karte mit allen {routesBoulders} für {competitionName}.',
    compClimbsDescList: 'Eine Auflistung aller {routesBoulders} für {competitionName}.',
    noCompetitionsMsg: 'Zur Zeit gibt es keine Wettkämpfe bei {gymName} ...',
    notParticipatingInMsg: 'Wettkämpfe, an denen Sie noch nicht teilnehmen:',
    competitionResults: 'Wettkampfsergebnisse',
    competition: 'Wettkampf',
    expiredComp: 'Vergangener Wettkampf',
    expiredComps: 'Vergangene Wettkämpfe',
    expiredCompsSubhead: 'Sieh die Ergebnisse an',
    noMoreLogging: 'Sie können nichts mehr für diesen Wettkampf eintragen.',
    logSubhead: 'Zeigt Begehungen seit dem Start von {compName} {startDate}',
    expiredToastr: 'Vergangener Wettkampf',
    expiredToastrMsgJoin: 'Die Frist ist bereits abgelaufen, du kannst jetzt nicht mehr teilnehmen',
    expiredToastrMsgQuit: 'Die Frist ist bereits abgelaufen, du kannst jetzt nicht mehr ausscheiden',
    participate: 'Teilnehmen',
    participated: 'Sie haben teilgenommen',
    participating: 'Du machst mit',
    subscribed: 'Sie haben sich angemeldet',
    logClimbs: 'Kletterrouten eintragen',
    notStarted: 'Diesen Wettkampf hat noch nicht begonnen',
    unranked: 'Ohne Rang',
    approvingMsg: 'Warten auf Genehmigung der Kletterhalle.',
    approvingMsg2: 'Du kannst jedoch bereits mit dem Eintragen beginnen.',
    approvingToastr: 'Bestätigung abwartend',
    approvingToastrMsg: 'Deine Anfrage muss von der Kletterhalle genehmigt werden.',
    approvedToastrMsg: 'Jetzt geh klettern!',
    genderRequiredMsg: 'Teilnahme erfordert ein Geschlecht',
    ageRequiredMsg: 'Teilnahme erfordert Ihr Alter',
    setGender: 'Geschlecht einstellen',
    setAge: 'Alter einstellen',
    unsubscribeTitle: 'Abmelden von {competitionName}',
    unsubscribeMsg: 'Willst du wirklich aufgeben?',
    unsubscribe: 'Abmelden',
    unsubscribedMsg: "Sie nehmen nicht mehr an '{competitionName}' teil.",
    start: 'Start',
    deadline: 'Deadline',
    ranking: 'Rangliste',
    toggleRanking: 'Rangliste wechseln',
    delayedScoresMsg: 'Die Ergebnisse für diesen Wettbewerb werden alle 30 Sekunden neu berechnet.',
    refresh: 'Ergebnisse aktualisieren',
    switchPoule: 'Poule wechseln',
    selectPoule: 'Poule auswählen',
    selectPouleExpl:
      'Dieser Wettbewerb funktioniert mit gruppierten Ranglisten. Wählen Sie die Poule aus, an der Sie teilnehmen möchten. Dies kann später geändert werden.',
    help: {
      btn: 'Hilfe',
      header: 'Über Wettkämpfe',
      generalTitle: 'Wie es funktioniert',
      generalExpl:
        'Ein Wettkampf ist eine Rangliste, die auf Begehungen basiert, die ausschließlich zwischen Startdatum/ -uhrzeit und der Deadline eingetragen wurden.',
      generalExpl2:
        'Wen du Sie vor dem Start des Wettkampfs bereits eine Route geklettert hast, musst du sie wiederholen, damit sie im Ranking zählt. Die Anzahl der Punkte pro Kletterroute ist über jeden Wettkampf aufgelistet.',
      poulesTitle: 'Gruppierte Ranglisten',
      poulesExpl:
        'Wettkämpfe können gruppierte Ranglisten oder "Poules" haben. Das heißt, du nimmst an einem Teilwettbewerb teil, der aus Kletterern mit etwa dem gleichen Kletterniveau besteht. Nach der Registrierung wirst du aufgefordert, dein eigenes (rotpunkt) Kletterniveau anzugeben, dementsprechend wird Ehrlichkeit geschätzt.',
    },
    info: {
      header: 'Regeln und Vorschriften',
      subhead: 'Spezifisch für {groupName}',
      rules: 'Regeln',
      pricesHeader: 'Preise zu gewinnen',
      scoreSystem: 'Bewertungssystem',
      points: {
        description:
          'Rangliste basierend auf Punkten. Jede Begehung erzielt Punkte, optional mehr, wenn sie in einem Versuch geklettert wird.',
        points: '{points} Punkte',
        bonus: '{points} Bonuspunkte',
      },
      pointsByGrade: {
        description:
          'Rangliste basierend auf Punkten. Jedes Begehung verdient Punkte, optional mehr, wenn sie in einem Versuch geklettert wird. Die Anzahl der Punkte pro Kletterroute ist abhängig vom Schwierigkeitsgrad der Route, entsprechend der Umrechnungstabelle. Wenn es Zonen gibt, zählt jede Zone als ein Top.',
        button: 'Umrechnungstabelle anzeigen',
        explanation: 'Punkte entsprechend des Schwierigkeitsgrads.',
        efforts:
          'Nur Ihre besten {efforts} Begehungen werden zur Berechnung Ihrer Punktzahl verwendet. Wenn es Zonen gibt, zählt jede Zone als eine Begehung.',
      },
      divBy: {
        description:
          'Jedes Top (und jede Zone) erhält 1000 geteilt durch die Anzahl der Teilnehmer, die es geklettert haben.',
        bonusPercent: '{percentage}% Bonuspunkte',
      },
    },
    setGrade: {
      header: 'Stelle deinen Teilnahme-Schwierigkeitsgrad ein',
      explanation:
        'Dieser Wettkampf arbeitet mit Gruppen, wo du nach der Kletterschwierigkeit eingestuft wirst. In deiner Gruppe konkurrierst du mit Kletterern, die ein ähnliches Kletterniveau haben.',
      explanation2:
        'Bitte lege deine aktuelle maximale (Rotpunkt) Kletterschwierigkeit fest. Dieser Schwierigkeitsgrad wird verwendet, um deine Gruppe zu bestimmen:',
      minGradeExplanation: 'Du hast gezeigt, dass du mindestens klettern kannst:  ',
    },
  },
  gym: {
    title: '{gymName} - Allgemeine Informationen | TopLogger',
    description: 'Kontaktinformationen, Öffnungszeiten und eine Karte für {gymName}.',
    currentGym: 'Aktuelle Kletterhalle:',
    contact: 'Kontakt',
    phone: 'Telefon',
    parking: 'Parkplatz',
    website: 'Webseite',
    gallery: 'Galerie',
    gallerySubhead: 'Einige Eindrücke von die halle',
    impression: 'Eindruck',
    hours: 'Öffnungszeiten',
    closed: 'Geschlossen',
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag',
    Saturday: 'Samstag',
    Sunday: 'Sonntag',
    location: 'Ort',
  },
  reservations: {
    title: 'Reservierungen für {gymName} | TopLogger ',
    description: 'Verfügbare Zeitfenster finden und reservieren {gymName}.',
    accountRequiredMsg: 'Sie benötigen ein Konto, um ein Zeitfenster reservieren zu können',
    slotsHeader: 'Verfügbare Zeitfenster',
    selectArea: 'Wählen Sie Ihren Bereich aus',
    spotsLeft: '{spots} übrige Plätze',
    dialogTitle: 'Buchen Sie Ihr Zeitfenster',
    notAvailableYet: 'Zurzeit nicht verfügbar',
    available: 'Verfügbar',
    customerInformation: 'Kundeninformation',
    associate: 'Kletterpartner',
    addAssociate: 'Kletterpartner hinzufügen',
    book: 'Buchen',
    myBookings: 'Meine Buchungen',
    expiredBookings: 'Abgelaufene Buchungen',
    lastMinuteBooking: 'Dies ist eine Last-Minute-Buchung',
    lastMinuteBookingExpl: 'Last-Minute-Buchungen zählen nicht zu Einschränkungen bei der Anzahl der Buchungen',
    fullyBooked: 'Ausgebucht',
    cancelBooking: 'Buchung stornieren',
    removeAssociate: 'Partner entfernen',
    bookingNumber: 'Buchungsnummer',
    clientNumber: 'Kundennummer',
    passwordHint: 'Das für dieses Zeitfenster spezifische Passwort',
    sendConfirmation: 'Bestätigungs-E-Mail senden',
    acceptContact: 'Ich erlaube der Halle, mich bei Änderungen meiner Reservierung zu kontaktieren',
    checkinTime: 'Check-in Zeit',
    checkout: 'Auschecken',
    checkOutTablet:
      'Geben Sie die E-Mail-Adresse ein, die für Ihre Reservierung zum Auschecken verwendet wurde. Alle Partner aus Ihrer Buchung werden ebenfalls ausgecheckt.',
    emails: {
      confirmation: {
        subject: 'Buchung bei {gym_name} bestätigt',
        confirmed: 'Buchung bestätigt',
        activity_at: '{activity} bei {gym_name}',
        confirmation: 'Ihre Buchung für {area_name} bei {gym_name} wurde bestätigt.',
        checkin_time: 'Check-in Zeit',
        booking_number: 'Buchungsnummer',
        manage_online: 'Sie können Ihre Buchungen online über TopLogger verwalten:',
        manage_booking: 'Buchung verwalten',
        thanks: 'Danke!',
      },
      no_show: {
        subject: 'Verpasste Buchung',
        you_are_marked: 'Ihre Buchung bei {gym_name} wurde als nicht erschienen markiert.',
        time_was: 'Die Check-in-Zeit lag zwischen {time_start} und {time_end} am {date}.',
        cancel:
          'Sie können Ihre Reservierungen jederzeit stornieren, indem Sie sich bei Ihrem TopLogger-Konto anmelden.',
        questions: 'Wenn Sie Fragen haben, wenden Sie sich bitte direkt an die Kletterhalle.',
      },
      checkoutReminder: {
        subject: 'Erinnerung zum Auschecken',
        reminder: 'Dies ist eine freundliche Erinnerung von {gym_name} dich am Schalter abzumelden.',
        time_is: 'Ihre Checkout-Zeit ist am {date} um {time_end}.',
      },
    },
  },
  help: {
    title: 'Hilfe | TopLogger',
    description:
      'Wie es funktioniert. Die Regeln zum Klettern, Toppen und Eintragen deiner Begehungen in der TopLogger Indoor-Kletter-App.',
    faq: {
      header: 'FAQ',
      subhead: 'Häufig gestellte Fragen',
      example: 'Beispiel',
      qGradeCalc: 'Wie wird mein  Schwierigkeitsgrad berechnet??',
      aGradeCalc:
        'Ihr Schwierigkeitsgrad wird anhand des Durchschnitts Ihrer 10 besten Begehungen in den letzten zwei Monaten berechnet.',
      aGradeCalcEx1:
        "Deine zehn am höchsten bewerteten Begehungen umfassen fünf 6a´s und fünf 6a⁺en. Das bedeutet, dass Ihre Note genau zwischen 6a und 6a+ liegt. Du bist 50% auf deinem Weg, 6a⁺ zu werden, also ist dein aktueller Schwierigkeitsgrad '6a mit 50%'.",
      aGradeCalcEx2:
        "Deine zehn bestklassierten Begehungen beinhalten eine 6a und neun 6a⁺en. Das heißt, dein Schwierigkeitsgrad ist fast 6a⁺: Du brauchst nur eine mehr. Du bist 90% auf deinem Weg, 6a⁺ zu werden, also ist dein derzeitiger Schwierigkeitsgrad '6a mit 90%'.",
      showPoints: 'Zeige Punktesystem',
      qTopFlash: 'Was ist der Unterschied zwischen Top und Flash?',
      qTopFlashRp: 'Was ist RP, FL und OS?',
      qCareTopFl: 'Warum sollte ich mich mit Top oder Flash befassen?',
      aCareTopFl:
        "Flash gibt dir mehr Punkte: Das Flashen eines Boulders fügt der Boulderstufe in der Berechnung deines Schwieriegkeitsgrades ein '⁺' hinzu.",
      aCareTopFlEx1:
        'Du hast eine 6a geflasht. In der Berechnung deines Schweierigkeitsgrades zählt diese Begehung, als ob du eine 6a⁺ geklettert hättest.',
      aCareTopFlEx2:
        'Du hast eine 6c⁺ geflasht. In der Berechnung deiner Schwierigkeitsgrades zählt dieser Aufstieg, als ob du eine 7a geklettert hättest.',
      qCareTopFlRp: 'Warum sollte ich mich mit Rotpunkt, Flash oder OnSight befassen?',
      aCareTopFlRp:
        'Flash und OnSight gibt dir mehr Punkte. Das Flashen einer Route fügt der Bewertung der Route in der Berechnung deners Schwierigkeitsgrades ein "⁺" hinzu. Beim On-sighten einer Route werden sogar zwei⁺e hinzugefügt.',
      aCareTopFlRpEx1:
        'Du hast eine 6a geflasht. In der Berechnung deines Schwierigkeitsgrades zählt diese Begehung, als ob du eine 6a⁺ geklettert hättest.',
      aCareTopFlRpEx2:
        'Du hast eine 6a geonsighted. In der Berechnung deines Schwieirgkeitsgrades zählt diese Begehung, als ob du eine 6b geklettert hättest.',
      aCareTopFlRpEx3:
        'Du hast eine 6c⁺ geflasht. In der Berechnung deines Schwieirgkeitsgrades zählt diese Begehung, als ob Sie eine 7a geklettert hättest.',
      aCareTopFlRpEx4:
        'Du hast eine 6c⁺ geonsighted. In der Berechnung deines Schwieirgkeitsgrades zählt diese Begehung, als ob du eine 7a⁺ geklettert hättest.',
      qScoreTime: 'Wie lange werden meine Begehungen zu meiner Bewertung zählen?',
      aScoreTimeGrade:
        'Deine 10 besten Begehungen in den letzten zwei Monaten tragen zu deiner Bewertung bei. Egal ob die Route von einer dieser Begehungen entfernt wird.',
      aScoreTimeCount:
        'Alle Begehungen von Kletterrouten, die gerade in der Kletterhalle sind, tragen zu deiner Bewertung bei. Wenn eine Kletterroute entfernt wird die Bewertung von jedem, der sie geklettert hat, um eins gesenkt.',
      qGradeStability: 'Was ist das für ein farbiger Punkt?',
    },
    guide: {
      header: 'Anfänger Anleitung zum Klettern',
      subhead: 'Die Spielregeln',
      intro:
        'Beim Bouldern geht es darum, mit einer einzigen Farbe ans Top zu kommen. Aber es gibt einige Regeln. Hier eine kurze Erklärung zu den Spielregeln:',
      starting: 'Starten',
      startingExpl1:
        'Starte mit den Hände an den, mit Startmarkierungen versehenen, Griffen. Stelle deine Füße überall hin, außer auf die Matte und hänge ruhig, bevor du anfängst zu klettern. Also kein Springen, außer es ist in der Beschreibung des Boulders angegeben.',
      startingExpl2:
        'Möglicherweise gibt es noch andere Hinweise, wie Sitzstart, was bedeutet, dass du aus einer sitzenden Position aus beginnen musst.',
      climbing: 'Klettern',
      climbingExpl: 'Klettern ist "einfach":Du darfst nur eine Farbe verwenden.',
      topping: 'Toppen',
      toppingExpl:
        'Du kannst sagen, dass du einen Boulder "getopt" hast, wenn du den Endgriff mit beiden Händen drei Sekunden lang berührt hat.',
      logging: 'Eintragen',
      loggingExpl: 'Das Eintragen deiner begangenen Routen kann auf drei Arten erfolgen:',
      loggingOs: 'On-Sight (OS) <small>Erster Versuch, keine Info</small>',
      loggingOsExpl:
        'Wenn du eine Route im ersten Versuch, ohne Informationen von anderen, geklettert hast, heißt das On-Sight',
      loggingFl: 'Flash (FL) <small>Erster Versuch, mit Info</small>',
      loggingFlExpl:
        'Wenn du die Route im ersten Versuch geklettert hast, aber Informationen darüber hattest, wie sie geht (oder nicht geht), heißt es "Flash". Dies ist z. B. der Fall, wenn du vorher jemanden den Boulder hast klettern sehen oder jemand dir gesagt hat, wie es geht.',
      loggingRp: 'Rotpunkt (RP) <small>der Rest</small>',
      loggingRpExpl: 'Wenn du den Boulder kletterst, aber nicht auf Anhieb, heißt das Rotpunkt.',
    },
  },
  contact: {
    title: 'Kontakt | TopLogger',
    description: 'Nimm Kontakt auf und hole dir TopLogger in deine Kletterhalle.',
    header: 'Kontakt',
    subhead: 'Lass uns in Kontakt treten',
    wantInGym: 'Willst du TopLogger in deiner Kletterhalle?',
    sendMail: 'Sende uns einfach eine E-Mail:',
  },
  feedback: {
    title: 'Feedback | TopLogger',
    description: 'Sag deine Meinung zu TopLogger.',
    header: 'Feedback senden',
    subhead: 'Schlage Änderungen oder Verbesserungen vor',
    forGym: 'Feedback zu {gymName}',
    forApp: 'Feedback zur App',
    explanation: 'Schlage Änderungen und Verbesserungen an der App vor oder berichte Fehler.',
    explanationGym:
      'Sag es {gymName}, was verbessert werden kÖnnte, was kaputt ist, oder verwenden Sie es einfach als Vorschlagskasten.',
    gymFeedbackMsg: 'Für fehlende Kletterrouten oder falsche Daten wende dich bitte an deine Kletterhalle.',
    message: 'Botschaft',
    feedbackGym: 'Feedback zu Gym',
    feedbackApp: 'App-Feedback',
    yourFeedback: 'Ihre Feedback',
    yourFeedbackSub: 'Antworten erhalten',
    reply: 'Antwort',
    retract: 'Widerrufen',
    inputLabel: 'Schreibe dein Feedback (anonym)',
    successMsg: 'Großartig! Vielen Dank für dein Feedback!',
  },
  terms: {
    title: 'Nutzungsbedingungen | TopLogger',
    description: 'Nutzungsbedingungen und Datenschutzbestimmungen für TopLogger',
  },
  components: {
    gradesChart: {
      header: 'Umrechnungstabelle für Schwierigkeitsgrade',
      explanation: 'Punkte in Bezug auf verschiedene Schwierigkeitssysteme',
    },
  },
  $vuetify, // Do not translate me please :)
}
